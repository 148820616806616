import React from 'react';

function Distribution() {
  const arrow = <svg viewBox="0 0 100 100" width="15"><polygon points="0 0, 80 50, 0 100" fill="#fff" /></svg>;
  return (
    <div className="container">
      <div className="separator" />
      <div className="card">
        <h3>{arrow}<span>Mise en place gratuite et facile</span></h3>
        <ul>
          <li>Aucun frais à avancer.</li>
          <li>Mise en place sous 72h.</li>
          <li>Sous la forme d'un dépliant, il ne prendra pas de place dans votre centre auditif.</li>
        </ul>
          <div className="illustration">
            <img src={process.env.PUBLIC_URL + '/images/leaflet.png'} className="leaflet" alt="Dépliant" />
            <img src={process.env.PUBLIC_URL + '/images/delivery.png'} className="delivery" alt="72h" />
          </div>
      </div>
      <div className="separator" />
      <div className="card">
        <h3>{arrow}<span>Déjà présent en centre auditif</span></h3>
        <img src={process.env.PUBLIC_URL + '/images/france.png'} className="map" alt="Carte de France" />
      </div>
      <div className="separator" />
      <div className="card">
        <h3>{arrow}<span>Cliniquement prouvée</span></h3>
        <ul>
          <li>Une étude comparative réalisée par le CHU de Rennes a eu lieu en 2019 entre le diagnostic acouphène de Diapason et la méthode classique par dichotomie Otosuite.</li>
          <li>Les résultats montrent que le diagnostic acouphène de Diapason est autant performant que celui réalisé traditionnellement en clinique.</li>
          <div className="illustration">
            <img src={process.env.PUBLIC_URL + '/images/chu-rennes.png'} className="chu-logo" alt="CHU de Rennes" />
            <img src={process.env.PUBLIC_URL + '/images/chu-results.svg'} className="chu-results" alt="CHU de Rennes" />
          </div>
        </ul>
      </div>
      <div className="separator" />
      <div className="card">
        <h3>{arrow}<span>Deux formules</span></h3>
        <ul>
          <li>Vous pouvez vendre Diapason seul.</li>
          <li>Vous pouvez vendre Diapason avec votre propre offre de suivi.</li>
        </ul>
        <img src={process.env.PUBLIC_URL + '/images/relaxation.png'} className="relaxation" alt="Relaxation" />
      </div>
      <div className="separator" />
      <div className="card">
        <h3>{arrow}<span>Diapason en bref</span></h3>
        <div className="cell2">
          <ul>
            <li>Dispositif médical de classe <code>I</code></li>
            <li>Traitement s'effectuant sur un smartphone ou tablette<br />avec un casque audio (ou des audioprothèses en bluetooth)</li>
            <li>Utilisation de thérapie sonore, thérapies cognitive et comportementale, relaxation, impulsions acoustiques…</li>
          </ul>
          <img src={process.env.PUBLIC_URL + '/images/ansm-logo.png'} className="ansm-logo" alt="Agence Nationale de sécurité du médicament et des produits de santé" />
        </div>
      </div>
      <div className="separator" />
      <div className="card large">
        <h3>{arrow}<span>Du point de vue audioprothésiste</span></h3>
        <ul>
          <li>Solution autonome ou complémentaire aux audioprothèses à proposer aux acouphéniques.</li>
        </ul>
      </div>
      <div className="separator" />
    </div>
  );
}

export default Distribution;
