import {
  Route,
  BrowserRouter as Router
} from 'react-router-dom';

import Distribution from './pages/Distribution';
import Home from './pages/LandingPageBrochure';
import LandingPageBrochure from './pages/LandingPageBrochure';
import React from 'react';

const relaxation = (
  <svg viewBox="0 0 128 128" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" d="M-1-1h582v402H-1z"/>
    <g>
      <path stroke-width="3" stroke-linejoin="round" stroke-linecap="round" stroke="#FFF" fill="none" d="M51.5 94.5s-15.8 3.6-24 8c-4.4 2.3-5.7 10.6-.2 13.5 10.2 5.2 47.2 6.5 47.2 6.5s23 .4 24-1c.6-.8-1.3-5-6-5-3 0-6-1.3-9-2-3.6-.9-3.3 1.8-6 1s-22.7-13-37-8M51.5 69.5s3.7 18.8 0 25M51.5 69.5l-7.7 15.3a7.4 7.4 0 01-7 4.7H14.6s-.4.6-3 1c-2.9.5-7.6 1.4-8-1s3-3 3-3M65.7 109.3c7.1-2.5 15.7-4.3 22.9-1.8M77.6 94.5s15.7 3.6 24 8c4.3 2.3 5.7 10.6.1 13.5M77.6 94.5c-3.8-6.2 0-25 0-25M77.6 69.5l7.7 15.3a7.4 7.4 0 007 4.7h22.3s.4.6 3 1c2.9.5 7.6 1.4 8-1s-3-3-3-3" class="cls-2"/>
      <path stroke-width="3" stroke-linejoin="round" stroke-linecap="round" stroke="#FFF" fill="none" d="M71.5 53.4s7 .1 11 5.6c2.4 3.3 6 12.5 8.7 18.5a5.9 5.9 0 004.8 4l19.3 2.3 4.3-1.3M60.5 47.5v2c0 3-3.2 3.5-5 4a13.9 13.9 0 00-9 5.5c-2.4 3.3-6 12.5-8.7 18.5a6 6 0 01-4.7 4l-19.3 2.3-4.3-1.3" class="cls-2"/>
      <path stroke-width="3" stroke-linejoin="round" stroke-linecap="round" stroke="#FFF" fill="none" d="M73.5 54c-2.7-1-5-2-5-5v-.5M74.5 37.5c0 5.8-5.6 11.2-10 11.2s-10-5.4-10-11.2v-4s6.6-2.3 10-7c3.4 4.2 10 7 10 7v4zM72 54.6a7.7 7.7 0 01-15 0" class="cls-2"/>
      <path stroke-width="3" stroke-linejoin="round" stroke-linecap="round" stroke="#FFF" fill="none" d="M83.5 52.1a6.2 6.2 0 00-4.4-6.3 5.1 5.1 0 001.7-4.8c-.3-1.2-.6-3.2-1-4.4-.9-3.2.5-4 0-7.8-.7-4.7-4.6-12-15.3-10.3-10.7-1.7-14.7 5.6-15.3 10.3-.5 3.8.9 4.6 0 7.8l-1 4.4a5.1 5.1 0 001.7 4.8 6.2 6.2 0 00-4.4 6.3M58.5 94.5h18" class="cls-2"/>
      <path stroke-width="3" stroke-linejoin="round" stroke-linecap="round" stroke="#FFF" fill="none" d="M67.5 34.5h-.6a1.4 1.4 0 00-1.4 1.4v4.6a1 1 0 01-1 1h-1M42.5 69.5l6 4M86.5 69.5l-6 4" class="cls-2"/>
    </g>
  </svg>
)

const sound = (
  <svg viewBox="-4 -4 24 24" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <path fill="#FFF" d="M0 15h3v1H0zM0 13h3v1H0zM0 11h3v1H0zM0 9h3v1H0zM4 15h3v1H4zM4 13h3v1H4zM4 11h3v1H4zM4 9h3v1H4zM4 7h3v1H4zM4 5h3v1H4zM8 15h3v1H8zM8 13h3v1H8zM8 11h3v1H8zM8 9h3v1H8zM8 7h3v1H8zM8 5h3v1H8zM8 3h3v1H8zM8 1h3v1H8zM12 15h3v1h-3zM12 13h3v1h-3zM12 11h3v1h-3zM12 9h3v1h-3zM12 7h3v1h-3zM12 5h3v1h-3z"/>
  </svg>
)

const learn = (
  <svg viewBox="-4 -4 40 40" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path stroke-width="1" stroke-linejoin="round" stroke-linecap="round" stroke="#fff" fill="none" class="cls-1" d="M3 27V5a4 4 0 014-4h22v22H7.2A4.1 4.1 0 003 26.6 4 4 0 007 31h22M27 23v8"/>
    </g>
  </svg>
)

const cbt = (
  <svg viewBox="3 -2 32 32" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 23.5h-2.6c-.8 0-1.4-.6-1.5-1.4l-.4-4h-1.7c-.4 0-.8-.2-1-.5v-1l2.4-5.1-.1-1.5c0-5 4.4-9 10-9 5.3 0 9.4 4.2 9.4 9.9 0 2.1-.6 4.2-1.6 6.2a.5.5 0 01-1-.4c1-2 1.6-3.8 1.6-5.8 0-5.1-3.7-9-8.5-9-5 0-9 3.6-9 8l.2 1.7v.2L10.2 17h2.2c.2 0 .4.3.5.5l.4 4.5c0 .3.2.5.5.5h2.6a.5.5 0 010 1z" fill="#fff"/>
    <path d="M22.4 18h-1.8a.5.5 0 01-.5-.5l-.3-1.3-1.2-.5-1.1.7c-.2.2-.5.1-.7 0L15.6 15a.5.5 0 01-.1-.6l.7-1.2-.5-1.2-1.3-.3a.5.5 0 01-.4-.5V9.5c0-.2.2-.4.4-.5l1.3-.3.5-1.2-.7-1.1a.5.5 0 010-.6l1.3-1.3c.2-.2.5-.2.7 0l1.1.7a6 6 0 011.2-.5l.3-1.4c0-.2.3-.4.5-.4h1.8c.2 0 .4.2.5.4l.3 1.4c.4 0 .8.3 1.2.5l1.1-.8h.7l1.2 1.4c.2.1.2.4.1.6l-.7 1.1.5 1.2 1.3.3c.2 0 .4.3.4.5v1.8c0 .3-.2.5-.4.5l-1.3.3-.5 1.2.7 1.2v.6l-1.3 1.3c-.2.1-.5.2-.7 0l-1.1-.7-1.2.5-.3 1.3c0 .3-.3.4-.5.4zM21 17h1l.3-1.3c0-.2.2-.4.3-.4a5 5 0 001.5-.6h.6l1 .7.7-.7-.7-1.1a.5.5 0 010-.5l.7-1.5c0-.2.2-.4.3-.4l1.3-.3v-1l-1.3-.2a.5.5 0 01-.3-.4 5 5 0 00-.7-1.5.5.5 0 010-.5l.7-1.1-.7-.7-1 .7H24a5 5 0 00-1.5-.6.5.5 0 01-.3-.4L22 3.9h-1l-.3 1.3c0 .2-.2.3-.3.4-.6 0-1 .3-1.5.6h-.6l-1-.7-.7.7.6 1c.2.2.2.4 0 .6a5 5 0 00-.6 1.5c0 .2-.2.3-.3.4l-1.3.2v1l1.3.3c.1 0 .3.2.3.4l.7 1.5v.5l-.7 1 .7.8 1-.7h.6c.4.3 1 .5 1.5.6.1 0 .3.2.3.4l.3 1.2zm.5-3a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm0-6a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" fill="#fff"/>
    <path d="M25.4 30H24a.5.5 0 01-.5-.5l-.3-1a5 5 0 01-.9-.4l-.9.6h-.6l-1-1.1a.5.5 0 01-.1-.7l.6-.9a5 5 0 01-.4-.9l-1-.2a.5.5 0 01-.5-.5v-1.5c0-.2.2-.4.4-.5l1-.2.5-1-.6-.8a.5.5 0 010-.7l1-1c.3-.2.5-.2.7 0l1 .5a5 5 0 01.8-.4l.3-1c0-.2.2-.4.5-.4h1.4c.3 0 .5.1.5.4l.3 1 .9.4.9-.6.6.1 1 1c.2.2.3.5.1.7l-.6.9.4.9 1 .2c.3 0 .5.3.5.5v1.5c0 .2-.2.4-.4.5l-1 .2-.5 1 .6.8c.2.2.1.5 0 .7l-1 1c-.3.2-.5.2-.7 0l-1-.5a5 5 0 01-.8.4l-.3 1c0 .3-.2.4-.5.4zm-1-1h.6l.3-1c0-.2.1-.4.3-.4a4 4 0 001.2-.5h.6l.8.5.5-.5-.6-.8a.5.5 0 010-.5c.3-.4.4-.8.5-1.2 0-.2.2-.3.4-.4l1-.2v-.7l-1-.2a.5.5 0 01-.4-.4 4 4 0 00-.5-1.2.5.5 0 010-.5l.6-.8-.5-.5-.8.5h-.6a4 4 0 00-1.2-.5.5.5 0 01-.3-.3l-.3-1h-.6l-.3 1c0 .1-.1.3-.3.3a4 4 0 00-1.2.5H22l-.8-.5-.5.5.6.8v.5a4 4 0 00-.5 1.2c0 .2-.2.4-.4.4l-1 .2v.7l1 .2c.2 0 .3.2.4.4 0 .4.2.8.5 1.2v.5l-.6.8.5.5.8-.5h.6l1.2.5c.2 0 .3.2.3.4l.3 1zm.3-2.4a3 3 0 110-6 3 3 0 010 6zm0-5a2 2 0 100 4 2 2 0 000-4z" fill="#fff"/>
  </svg>
)

export default class App extends React.Component {

  state = {
    index: 0,
  }

  displayNextSlide() {
    this.setState({index: (this.state.index+1)%4});
    setTimeout(() => {
      this.displayNextSlide();
    }, 5000);
  }

  componentDidMount() {
    setTimeout(() => {
      this.displayNextSlide();
    }, 3000);
  }

  render() {
    return (
      <Router>
        <div className="app">
          <header className="header">
            <nav>
              <a href="https://www.diapason-app.com">
                <img src={process.env.PUBLIC_URL + '/images/diapason-logo.svg'} className="logo" alt="Diapason" />
                <div className="titleContainer">
                  <h1>DIAPASON</h1>
                  <span>L'application mobile qui&nbsp;<strong>accompagne</strong><br />vos&nbsp;patients entre les&nbsp;séances.</span>
                </div>
              </a>
            </nav>
            {this.renderSubtitle()}
          </header>
          <Route exact path="/" component={Home}/>
          <Route exact path="/distribution" component={Distribution}/>
          <Route exact path="/decouvrir" component={LandingPageBrochure}/>
          <footer>
            <h2>Intéressé(e)&nbsp;? Une&nbsp;question&nbsp;? On&nbsp;vous&nbsp;rappelle&nbsp;?</h2>
            <p>Écrivez-nous sur <a href="mailto:pro@diapason-app.com?Subject=Diapason Pro - Prise de contact">pro@diapason-app.com</a> et nous vous recontacterons rapidement.</p>
            <p>Vous pouvez également nous contacter sur notre <a href="#" onClick={(e) => { e.preventDefault(); window.$crisp.push(['do', 'chat:open'])}}>service de discussion (chat)</a></p>
            <div className="legals">
              Diapason est un dispositif médical de&nbsp;Immersive&nbsp;Therapy.<br />
              S.A.S. au capital de 30.000€ - SIRET&nbsp;833&nbsp;567&nbsp;266&nbsp;00022 RCS&nbsp;RENNES<br />
              21,&nbsp;La&nbsp;Sapinière - 35340&nbsp;LA&nbsp;BOUËXIÈRE - FRANCE<br />
              <a href="https://diapason-app.com" rel="noopener noreferrer" target="_blank">www.diapason-app.com</a>
            </div>
          </footer>
        </div>
      </Router>
    );
  }

  renderSubtitle() {
    if (window.location.href.indexOf('/decouvrir') === -1) {
      return (
        <div className="subtitle">
          <h2>Vous êtes un professionnel de&nbsp;santé&nbsp;?</h2>
          <p>Vous pouvez distribuer <strong>Diapason pour acouphènes</strong> et le proposer à vos&nbsp;patients.</p>
        </div>
      )
    } else {
      return (
        <>
          <div className="subtitle-pros">
            <ul className="decouvrir">
              <li><em>Attentif.</em> Un suivi hebdomadaire de l'acouphène qui permet d'adapter les&nbsp;activités.</li>
              <li><em>Prévoyant.</em> Des notifications quotidiennes pour les têtes en l'air ;-)</li>
              <li><em>Nomade.</em> 15 minutes d'activités ludiques par jour, à réaliser où l'on veut.</li>
              <li><em>Sur mesure.</em> Un programme 100% personnalisé&nbsp;: à chacun son acouphène&nbsp;!</li>
            </ul>
          </div>
          <div className="slides">
            <div className="slide" style={{opacity: this.state.index===0?1:0}}>
              <span className="title">Attentif</span>
              <span className="text">Un suivi hebdomadaire de l'acouphène qui permet d'adapter les activités.</span>
            </div>
            <div className="slide" style={{opacity: this.state.index===1?1:0}}>
              <span className="title">Prévoyant</span>
              <span className="text">Des notifications quotidiennes pour les têtes en l'air ;-)</span>
            </div>
            <div className="slide" style={{opacity: this.state.index===2?1:0}}>
              <span className="title">Nomade</span>
              <span className="text">15 minutes d'activités ludiques par jour, à réaliser où l'on veut.</span>
            </div>
            <div className="slide" style={{opacity: this.state.index===3?1:0}}>
              <span className="title">Sur mesure</span>
              <span className="text">Un programme 100% personnalisé&nbsp;: à chacun son acouphène&nbsp;!</span>
            </div>
          </div>
          <div className="iconsContainer">
            <div className="icons">
              <div className="icon sound"><div>{sound}</div><span>Rééducation auditive</span></div>
              <div className="icon learn"><div>{learn}</div><span>Connaissances</span></div>
              <div className="icon"><div>{cbt}</div><span>Thérapies cognitives comportementales</span></div>
              <div className="icon"><div>{relaxation}</div><span>Exercices de relaxation</span></div>
            </div>
          </div>
        </>
      )
    }
  }
}
