import '../styles/landingpagebrochure.scss';

import API from '../utils/API';
import React from 'react';

export default class LandingPageBrochure extends React.Component {

  constructor() {
    super();
    this.state = {
      step: 'VOID',
      loading: false,
      error: null,
      contact: '',
      phone: '',
      email: '',
      address: '',
      note: '',
      bonus: 'Non précisé',
      cta: 'mobile',
    }

    this.api = new API((translate)=>(translate));
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  submitContactForm = async (event) => {
    event.preventDefault();      
    if (!this.state.address.match(/[0-9]{5}/)) {
      this.setState({loading: false, error: `On dirait qu'il n'y a pas de code postal dans le champ "Adresse postale". Nous allons en avoir besoin pour vous envoyer les brochures ;-)`, showError: true});
      return;
    }

    this.setState({loading: true, error: null, message: `Envoi de la demande en cours…`});
    try {
      await this.api.sendProContactForm(this.state.contact, this.state.phone, this.state.email, this.state.address, this.state.note, { bonus: this.state.bonus, cta: this.state.cta });
      this.setState({message: `Merci ! La demande a bien été enregistrée. Nous prendons contact avec vous rapidement.`});
    } catch (e) {
      let error = e.message;
      this.setState({loading: false, error, showError: true});
    }
  }

  renderFormError = (error) =>{
    return (
      <div className="error">
        <div dangerouslySetInnerHTML={{__html: error}} />
        <button type="button" onClick={(e) => { 
          this.setState({showError: false});
          setTimeout(() => this.setState({error: null}), 400);
          return true;
        }}>OK</button>  
      </div>
    )
  }

  render() {
    return (
      <div className="container">
        <main className="pros">
          <section className="part2">
            <div className="options">
              <div className="header">
                <div className="description">
                  <h2>Pour <em>proposer Diapason</em> dans votre cabinet, c'est tout simple.</h2>
                  <p className="shortDescription coupon">Nous vous envoyons des brochures avec un <span>code promo de&nbsp;20€</span> pour vos patients.</p>
                  <p className="shortDescription">Si vous êtes intéressé, vous pouvez remplir le formulaire ci-dessous et nous vous recontactons rapidement.</p>
                </div>
              </div>

              <div className="desktopfeatures">
                <div className="feature">
                  <h3><span>Formule</span>Brochures<div className="decoration" /></h3>
                  <ul>
                    <li>Code promo de 20€ pour vos&nbsp;patients</li>
                    <li>Accès gratuit et complet à l'application pour vous</li>
                    <li className="nobullet"><div className="divider divider2" /></li>
                    <li className="special">Mise en place en moins de 5&nbsp;jours&nbsp;ouvrés</li>
                    <li className="placeholder">dd<br />dd</li>
                  </ul>
                  <div className="button">
                    <input type="button" value="Recevoir les brochures" onClick={() => 
                      this.setState({
                        bonus:`Non, je ne suis pas intéressé(e)`,
                        cta: `desktop-brochures`,
                        formTitle:`Recevoir les brochures Diapason`})
                    }/>
                  </div>
                </div>
                <div className="feature">
                  <h3><span>Formule</span>Contrat<div className="decoration" /></h3>
                  <ul>
                    <li>Code promo de 20€ pour vos&nbsp;patients</li>
                    <li>Accès gratuit et complet à l'application pour vous</li>
                    <li className="nobullet"><div className="divider divider2" /></li>
                    <li className="special">Signature d'un contrat de référencement</li>
                    <li className="special">Commission pour chaque code&nbsp;promo utilisé</li>
                  </ul>
                  <div className="button">
                    <input type="submit" value="Recevoir le contrat" onClick={() => 
                      this.setState({
                        bonus: `Oui, je suis intéressé(e)`,
                        cta: `desktop-contrat`,
                        formTitle: `Recevoir le contrat Diapason`})
                    }/>
                  </div>
                </div>
              </div>

                <form onSubmit={this.submitContactForm} className={!this.state.formTitle?'hidden':null}>
                  <div className={['indicator', (this.state.loading || this.state.showError) ? 'loading' : null].join(' ')}>
                    {this.state.error?this.renderFormError(this.state.error):this.state.message}
                  </div>
                  <div className="title">
                    {this.state.formTitle}
                  </div>
                  <p>
                    <label>Nom et prénom *</label>
                    <input 
                      type="text"
                      name="contact"
                      required={true}
                      value={this.state.contact}
                      onChange={this.handleChange}
                      placeholder="DUPONT Jean"
                      />
                  </p>
                  <p>
                    <label>Téléphone</label>
                    <input 
                      type="phone"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleChange}
                      placeholder="06 99 54 26 17"
                      autoComplete="phone"
                      />
                  </p>
                  <p>
                    <label>Adresse email *</label>
                    <input 
                      type="email"
                      name="email"
                      required={true}
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="jean.dupont@orange.fr"
                      autoComplete="email"
                      />
                  </p>
                  <p>
                    <label>Adresse postale *</label>
                    <input 
                      type="text"
                      name="address"
                      required={true}
                      value={this.state.address}
                      onChange={this.handleChange}
                      placeholder="2 rue de la paix, 33000 Bordeaux"
                      autoComplete="address"
                      />
                  </p>
                  <p>
                    <label>Message</label>
                    <textarea 
                      name="note"
                      value={this.state.note}
                      onChange={this.handleChange}
                      placeholder="Si vous avez une question ou une préférence si nous devons vous recontacter (email, téléphone après 18h…)"
                      />
                  </p>
                  <div className="actions">
                    <div className="button">
                      <input type="submit" value="Valider" />
                    </div>
                  </div>
                </form>
                <div className="illustration" />
            </div>
          </section>
        </main>
      </div>
    );
  }
}