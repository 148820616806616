import { apiUrl } from '../constants';

export default class Request {
  static async get(path, queryString) {
    return await Request.fetch('GET', path, queryString, null)
  }
  static async post(path, queryString, data, pToken = 'accessToken') {
    return await Request.fetch('POST', path, queryString, data, pToken)
  }
  static async patch(path, queryString, data) {
    return await Request.fetch('PATCH', path, queryString, data)
  }
  static async refreshToken(pTryCount=1) {
    const data = (await Request.fetch('post', '/refresh-tokens', null, null, 'refreshToken', pTryCount)).json
    localStorage.setItem('accessToken', data.accessToken)
  }
  static async fetch(pMethod, pPath, pQueryString = null, pBody = null, pToken = 'accessToken', pTryCount=1) {
    let baseUrl = apiUrl;
    let url = baseUrl + pPath;

    let body = null;
    if (pBody) {
      body = JSON.stringify(pBody);
    }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': 'fr',
      'Platform': 'web'
    }

    if (pToken) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem(pToken);
    }

    let response = null
    try {
      response = await fetch(url, {
        method: pMethod,
        headers,
        body
      })
    } catch (e) {
      if (e.message.indexOf('Failed to fetch') > -1) {
        const data = { name: 'UnreachableServer' }
        const blob = new Blob([JSON.stringify(data, null, 2)], {type : 'application/json'})
        return new Response(blob, { 'status': 500 })
      }
      throw e
    }

    if (response.status === 204) {
      return response
    }

    response.json = await response.json();

    if (response.status === 401 && response.json.name === 'ExpiredTokenError' && pTryCount < 5) {
        pTryCount++;
        await Request.refreshToken(pTryCount);
        response = await Request.fetch(pMethod, pPath, pQueryString, pBody, pToken, pTryCount);
    }

    return response
  }

}