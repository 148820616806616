import Request  from './Request'

export default class API {

  constructor(t) {
    this.t = t
  }
  async sendProContactForm(contact, phone, email, address, note, data) {
    const response = await Request.post('/diapason/contacts', null, { 
      forward: 'distribution', contact, phone, email, address, note, data
    })
    const result = response.json
    if (response.status >= 400) {
      switch (result.name) {
        case 'UnreachableServer': {
          throw Error(this.t('error.timeout'))
        }
        default: {
          console.error('sendProContactForm()', result);
          throw Error('Il y a eu une erreur inattendue. Vous pouvez réessayer plus tard ou nous contacter directement sur pro@diapason-app.com');
        }
      }
    }
    return result
  }

}
